<template>
  <div class="container page_answer">
    <van-nav-bar
      :title="get_nav_title()"
      left-arrow
      class="nav_bar"
      @click-left="nav_back"
    />

    <!-- 答题时 -->
    <div v-if="!is_over">
      <!-- 答题状态 -->
      <div class="answer_info">
        <!-- 答对个数 -->
        <van-icon name="success" class="icon" color="#2196f3" size="20px" />
        <span>{{ right }}</span>

        <!-- 答错个数 -->
        <van-icon name="cross" class="icon" color="#f44336" size="20px" />
        <span>{{ wrong }}</span>

        <img
          src="~@/assets/img/practice/fav_off.png"
          class="fav"
          v-if="question.is_favorited == 0"
          @click="fav()"
        />
        <img
          src="~@/assets/img/practice/fav_on.png"
          class="fav"
          v-if="question.is_favorited == 1"
          @click="cancel_fav()"
        />
      </div>

      <!-- 问答内容 -->
      <div class="qa_content_box">
        <question-answer
          :question_num="question_num"
          :question="question"
          :is_view="is_view"
          @change="on_answer_change"
        />
      </div>

      <div class="clr"></div>

      <div class="next_question" v-if="show_next" @click="next_question()">
        下一题
      </div>
    </div>

    <!-- 提交答案后 -->
    <div class="result_outter" v-if="is_over">
      <!-- 答题总情况 -->
      <div class="result_info">
        <p>学习内容：{{ part_result_data.range }}</p>
        <p>题目数量：{{ part_result_data.questions.length }}</p>
        <p>练习时长：{{ format_seconds(part_result_data.time) }}</p>

        <div class="right_wrong_box">
          <div class="left_box">
            <van-icon
              name="success"
              class="right"
              color="#2196f3"
              size="22px"
            />
            <div class="left">{{ part_result_data.right_count }}</div>
          </div>
          <div class="right_box">
            <van-icon name="cross" class="wrong" color="#f44336" size="22px" />
            <div>{{ part_result_data.wrong_count }}</div>
          </div>
        </div>
      </div>

      <!-- 答题详情 -->
      <div class="result_detail_box">
        <div class="result_detail_change_box">
          <span
            :class="get_result_detail_change_box_span_class(0)"
            @click="result_detail_change_click(0)"
            >全部</span
          >
          <span
            :class="get_result_detail_change_box_span_class(1)"
            @click="result_detail_change_click(1)"
            >答错</span
          >
        </div>
      </div>
    </div>

    <!-- 答题详情题目列表 -->
    <div class="result_detail_list_box" v-if="is_over">
      <van-swipe
        class="my-swipe"
        :show-indicators="false"
        :initial-swipe="result_detail_list_box_idx"
        @change="on_result_detail_list_box_change"
      >
        <!-- 全部 -->
        <van-swipe-item>
          <div
            class="result_detail_list"
            :class="result_detail_list_class(0)"
            v-for="(item, idx) in part_result_data.questions"
            :key="idx"
          >
            <div
              class="qa_right_wrong_state"
              :class="'qa_right_wrong_state_' + item.status"
            >
              <van-icon
                name="success"
                color="#fff"
                size="20px"
                v-if="item.status == 1"
              />
              <van-icon
                name="cross"
                color="#fff"
                size="20px"
                v-if="item.status == 2"
              />
            </div>
            <div class="question_content">
              <div class="question" v-html="item.question"></div>
              <div class="answer" v-html="item.answer_text"></div>
            </div>

            <img
              src="~@/assets/img/practice/fav_off.png"
              class="fav"
              v-if="item.is_favorited == 0"
              @click="fav2(item.id)"
            />
            <img
              src="~@/assets/img/practice/fav_on.png"
              class="fav"
              v-if="item.is_favorited == 1"
              @click="cancel_fav2(item.id)"
            />
          </div>
        </van-swipe-item>
        <!-- 答错的 -->
        <van-swipe-item>
          <div
            class="result_detail_list"
            :class="result_detail_list_class(1)"
            v-for="(item, idx) in part_result_data.questions_wrong"
            :key="idx"
          >
            <div
              class="qa_right_wrong_state"
              :class="'qa_right_wrong_state_' + item.status"
            >
              <van-icon
                name="success"
                color="#fff"
                size="20px"
                v-if="item.status == 1"
              />
              <van-icon
                name="cross"
                color="#fff"
                size="20px"
                v-if="item.status == 2"
              />
            </div>
            <div class="question_content">
              <div class="question" v-html="item.question"></div>
              <div class="answer" v-html="item.answer_text"></div>
            </div>

            <img
              src="~@/assets/img/practice/fav_off.png"
              class="fav"
              v-if="item.is_favorited == 0"
              @click="fav2(item.id)"
            />
            <img
              src="~@/assets/img/practice/fav_on.png"
              class="fav"
              v-if="item.is_favorited == 1"
              @click="cancel_fav2(item.id)"
            />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 继续练习 + 返回 -->
    <div class="ctl_box" v-if="is_over">
      <div @click="re_practice()">重新选题</div>
      <div @click="nav_back()">返回</div>
    </div>
  </div>
</template>
<script>
import { NavBar, Icon, Swipe, SwipeItem, Toast } from "vant";
import QuestionAnswer from "../../packages/qa/qa.vue";
import {
  favorite_question,
  favorite_question_cancel,
  submit_set_answer
} from "@/api/practice/api";
import {
  save_part_answer,
  clear_part_answer,
  get_part_answer,
  format_seconds
} from "@/utils/common";

export default {
  name: "Answer",
  data() {
    // let question = this.mock_judgment();
    let question = {};
    return {
      question_num: 0, //当前题目在本组中的序号
      all_questions: [],
      question: question,
      wrong: 0,
      right: 0,
      is_view: false,
      is_over: false,
      user_answer: [],
      show_next: false,
      is_get_question: 0,
      result_detail_list_box_idx: 0,
      result_detail_list_box_idx_ani: 0,
      part_result_data: {}
    };
  },
  watch: {
    $router: {
      handler: "get_now_question", //调用方法
      immediate: true //进入立即执行一次
    }
  },
  methods: {
    nav_back() {
      this.$router.push({ path: "/question_card?ts=" + Math.random() });
    },
    //获取标题
    get_nav_title(){
      if(this.is_over==0){
        return this.question_num + '/' + this.all_questions.length
      }

      return "小组练习完成"
    },
    //提交单个题目答案
    on_answer_change(data) {
      //本题已答
      this.user_answer.push(data);
      let qa = 2;
      if (data.result) {
        qa = 1;
      }

      let part_id = this.$store.getters.part_id;
      save_part_answer(part_id, data.question_id, qa);

      this.all_questions[this.question_num - 1]["status"] = qa;
      if (data.result) {
        this.right++;
        setTimeout(() => {
          this.next_question();
        }, 200);
      } else {
        this.wrong++;
        this.show_next = true;
      }
    },
    //设置当前题目
    get_now_question() {
      let now_questions = this.$store.getters.now_question;
      let set_id = this.$store.getters.set_id;
      if (now_questions.length == 0) {
        Toast("未发现题目", 1000);

        if (set_id == "") {
          location.replace("/practice?ts=" + Math.random());
          return;
        }
        setTimeout(() => {
          this.nav_back();
        }, 1000);
        return;
      }

      this.is_over = false;
      this.all_questions = now_questions;
      this.calc_questions_answer_right_wrong_info();
      this.next_question();
    },
    //计算当前答题正确和错误情况，并获取下一题的题号和是否获取到题目
    calc_questions_answer_right_wrong_info() {
      let wrong = 0;
      let right = 0;
      let is_get_question = 0;
      for (let i = 0; i < this.all_questions.length; i++) {
        if (this.all_questions[i]["status"] == 0 && is_get_question == 0) {
          this.question_num = i + 1;
          is_get_question = 1;
        }

        if (this.all_questions[i]["status"] == 1) {
          right++;
        }

        if (this.all_questions[i]["status"] == 2) {
          wrong++;
        }
      }

      this.is_get_question = is_get_question;
      this.right = right;
      this.wrong = wrong;
    },
    //下一题
    next_question() {
      this.calc_questions_answer_right_wrong_info();
      if (this.is_get_question == 1) {
        this.question = this.all_questions[this.question_num - 1];
        this.show_next = false;
        return;
      }
      this.submit_answer();
    },
    //提交答案并进入下一组
    submit_answer() {
      console.log("提交答案并进入下一组");
      this.show_next = false;

      let part_id = this.$store.getters.part_id;

      //合并用户答案
      let history_user_answer = get_part_answer(part_id);
      let user_answer = [];
      for (let i = 0; i < history_user_answer.length; i++) {
        let qa = false;
        if (history_user_answer[i]["r"] == 1) {
          qa = true;
        }
        user_answer.push({
          question_id: history_user_answer[i]["id"],
          result: qa
        });
      }

      submit_set_answer({
        part_id: part_id,
        time: 100,
        results: user_answer
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }

        //清空本组数据
        clear_part_answer(part_id);

        if (res.data.status == 0) {
          Toast("本组练习完成，进入下一组");
          this.nav_back()
          return;
        }

        console.log("显示最终结果");
        this.is_over = true;

        let questions_wrong = [];
        for (let i = 0; i < res.data.questions.length; i++) {
          if (res.data.questions[i]["status"] == 2) {
            questions_wrong.push(res.data.questions[i]);
          }
        }
        res.data["questions_wrong"] = questions_wrong;
        console.log(res.data);
        this.part_result_data = res.data;
      });
    },
    //重新练习
    re_practice() {
      this.$router.push({ path: "/get_question_config?ts=" + Math.random() });
    },
    //收藏
    fav() {
      let id = this.question.id;
      favorite_question({
        question_id: id
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }
        this.question.is_favorited = 1;
        this.all_questions[this.question_num - 1]["is_favorited"] = 1;
      });
    },
    //取消收藏
    cancel_fav() {
      let id = this.question.id;
      favorite_question_cancel({
        question_id: id
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }
        this.question.is_favorited = 0;
        this.all_questions[this.question_num - 1]["is_favorited"] = 0;
      });
    },
    //解析-收藏
    fav2(id) {
      favorite_question({
        question_id: id
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }

        for (let i = 0; i < this.part_result_data.questions.length; i++) {
          if (this.part_result_data.questions[i]["id"] == id) {
            this.part_result_data.questions[i]["is_favorited"] = 1;
          }
        }
        for (let i = 0; i < this.part_result_data.questions_wrong.length; i++) {
          if (this.part_result_data.questions_wrong[i]["id"] == id) {
            this.part_result_data.questions_wrong[i]["is_favorited"] = 1;
          }
        }
      });
    },
    //解析-取消收藏
    cancel_fav2(id) {
      favorite_question_cancel({
        question_id: id
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }
        for (let i = 0; i < this.part_result_data.questions.length; i++) {
          if (this.part_result_data.questions[i]["id"] == id) {
            this.part_result_data.questions[i]["is_favorited"] = 0;
          }
        }
        for (let i = 0; i < this.part_result_data.questions_wrong.length; i++) {
          if (this.part_result_data.questions_wrong[i]["id"] == id) {
            this.part_result_data.questions_wrong[i]["is_favorited"] = 0;
          }
        }
      });
    },
    //解析标准答案
    parse_standard_answer(question) {
      if (question.question_type == 1) {
        //选择题
        return (
          String.fromCharCode(65 + question.answer) +
          "、" +
          question.answer_text
        );
      }
      if (question.question_type == 2) {
        //判断题
        return question.answer_text;
      }

      return question.answer_text;
    },
    //答对答错滑动
    on_result_detail_list_box_change(idx) {
      this.result_detail_list_box_idx_ani = idx;
    },
    //获取答对答错Tab class
    get_result_detail_change_box_span_class(idx) {
      if (this.result_detail_list_box_idx_ani == idx) {
        return "active";
      }
      return "";
    },
    //单击答错答对tab按钮
    result_detail_change_click(idx) {
      this.result_detail_list_box_idx = idx;
      this.result_detail_list_box_idx_ani = idx;
    },
    //处理秒数转时分秒
    format_seconds(val){
      return format_seconds(val)
    },
    //模拟单选题数据
    mock_single() {
      let questions = {
        type: 1,
        question: "职业道德是 ____________ 所应遵循的道德原则和规范的总和。",
        options: [
          {
            num: "A",
            content: "人们在家庭生活中<br/>人们在家庭生活中"
          },
          {
            num: "B",
            content: "人们在职业工作和劳动中"
          },
          {
            num: "C",
            content: "人们在与人交往中"
          },
          {
            num: "D",
            content: "人们在消费领域中"
          }
        ],
        answer: "B"
      };

      return questions;
    },
    //模拟判断题数据
    mock_judgment() {
      let questions = {
        type: 1,
        question: "职业道德是人们在与人交往中所应遵循的道德原则和规范的总和。",
        options: [
          {
            num: "A",
            content: "对"
          },
          {
            num: "B",
            content: "错"
          }
        ],
        answer: "B"
      };

      return questions;
    },
    //获取列表显示状态class
    result_detail_list_class(idx){
        if(this.result_detail_list_box_idx_ani!=idx){
            return "hide"
        }
        return ""
    }
  },
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    QuestionAnswer
  }
};
</script>
<style lang="stylus">
.page_answer .nav_bar{
    background #fff
    border-bottom 0
}
.page_answer .van-nav-bar__content{
    height: 54px;
}
.page_answer .van-nav-bar__title{
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000;
}
.page_answer .van-hairline--bottom::after{
    border none
}
.page_answer .van-nav-bar .van-icon{
    color #000
    font-size 18px
}
.page_answer .van-nav-bar .van-icon{
    font-size:22px !important
}
</style>

<style lang="stylus" scoped>
.container{
    width 100%
    height 100%
    background #fff
    position relative
}
.answer_info{
  margin-top 20px
  position relative
  width 100%
  height 40px
}
  span{
    margin-left 10px
    font-size 18px
    vertical-align: middle;
  }
  .icon{
    margin-left 30px
    vertical-align: middle;
  }
  .fav{
    width 50px
    position absolute
    top -8px
    right 5%
  }
.qa_content_box{ margin-top:20px}
.next_question{
  width 80%
  margin 0 auto
  margin-top 10px
  height: 48px;
  line-height 48px
  border-radius: 24px;
  border: 2px solid #FF7E5C
  color #FF7E5C
  text-align center
}
</style>

<style lang="stylus" scoped>
 .result_info {
   border 1px solid #ccc
   border-radius 8px
   padding 10px 20px
   box-sizing border-box
   width 90%
   margin 0 auto
   position relative
}
.result_info p{
    font-size: 12px;
    color: #888;
    line-height: 20px;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    word-break: break-all;
    white-space: nowrap;
}
.result_info .right_wrong_box{
  position: absolute;
  right: 20px;
  top: 20px;
  width: 86px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.result_info .right_wrong_box >div{
  flex 1
  font-size: 17px;
}
.result_detail_box{
  margin-top: 25px;
  text-align: center;
  height: 50px;
  background: #fff;
  z-index: 1;
  position: relative;
}
.result_detail_box .result_detail_change_box span{
  font-size 16px
  color #ccc
  cursor pointer
  padding 0 15px 5px 15px
}
.result_detail_box .result_detail_change_box .active{
  color #000
  border-bottom 4px solid #ffc107
  font-weight bold
}
.result_detail_list_box{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 237px;
  padding-bottom: 88px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;
}
.result_detail_list_box .result_detail_list{
  margin-top: 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
  text-align: left;
  padding: 10px 60px 10px 60px;
  position relative
}
.result_detail_list_box .result_detail_list .qa_right_wrong_state{
  position: absolute;
  background: #afd9fb;
  color: #fff;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  line-height: 45px;
  text-align: center;
  left: 13px;
  top: 50%;
  margin-top: -15px;
}


.result_detail_list_box .result_detail_list .qa_right_wrong_state.qa_right_wrong_state_2{
  background #ffd4d4
}
.result_detail_list_box .result_detail_list .question_content{

}
.result_detail_list_box .result_detail_list .question_content .question{
  font-size 14px
  font-weight 600
}
.result_detail_list_box .result_detail_list .question_content .answer{
  margin-top 10px
  color #aaa
}
.result_detail_list_box .result_detail_list .fav{
  position: absolute;
  top: 50%;
  height: 50px;
  margin-top: -21px;
  right: 2px;
}
.ctl_box{
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-between;
  background #fff
  padding-top 10px
  height 70px
}
.ctl_box div{
  text-align: center;
  width: 45%;
  border-radius: 50px;
  border: 2px solid #ffc107;
  line-height: 40px;
  height: 40px;
  margin: 0 5%;
}
.result_outter{
  background #fff
  position relative
  z-index 1;
  padding-top 20px
}
</style>
