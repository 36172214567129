import Cookies from "js-cookie";
//获取筛选项
export function get_filter_item(item_name) {
    let item = Cookies.get(item_name);
    if (item == undefined || item > 1 || item < 0) {
        item = 1;
        Cookies.set(item_name, 1);
    }
    return item
}

export function int_to_boolean(i) {
    if (i == 1) {
        return true
    }
    return false
}


export function boolean_to_int(b) {
    if (b) {
        return 1
    }
    return 0
}

//数组按照指定长度切割
export function arr_chunk(array, size = 0) {
    //获取数组的长度，如果你传入的不是数组，那么获取到的就是undefined
    var length = array.length;
    //判断不是数组，或者size没有设置，size小于1，就返回空数组
    if (!length || !size || size < 1) {
        return []
    };
    var index = 0 //用来表示切割元素的范围start
    var resIndex = 0 //用来递增表示输出数组的下标
    //根据length和size算出输出数组的长度，并且创建它。
    var num = Math.ceil(length / size);
    var result = new Array(num);
    //进行循环
    while (index < length) {
        //循环过程中设置result[0]和result[1]的值。该值根据array.slice切割得到。
        result[resIndex++] = array.slice(index, (index += size));
    };
    return result
}

//答题数据存储到storage
export function save_part_answer(part_id, question_id, status) {
    let sdata = localStorage.getItem("part_" + part_id)
    let r = [];
    if (sdata != null && sdata != "") {
        r = JSON.parse(sdata)
    }

    let check_exists = 0;
    for (let i = 0; i < r.length; i++) {
        if (r[i]['id'] == question_id) {
            r[i]['r'] = status;
            check_exists = 1;
        }
    }

    if (check_exists == 0) {
        r.push({
            id: question_id,
            r: status
        })
    }
    console.log(r)
    localStorage.setItem("part_" + part_id, JSON.stringify(r))
}

//从storage取答题数据
export function get_part_answer(part_id) {
    let sdata = localStorage.getItem("part_" + part_id)
    let r = [];
    if (sdata != null && sdata != "") {
        r = JSON.parse(sdata)
    }
    return r
}

//从storage清空答题数据
export function clear_part_answer(part_id) {
    localStorage.removeItem("part_" + part_id)
}

export function format_seconds(val) {
    let result = parseInt(val)
    let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
 
    return  `${h}:${m}:${s}`;
    // if(h !== '00') res += `${h}h`;
    // if(m !== '00') res += `${m}min`;
    // res += `${s}s`;
    // return res;
}