<template>
  <div class="undefin-wrapper">该题型暂不支持，敬请期待！</div>
</template>
<script>
export default {
  name: "Undefind"
};
</script>
<style lang="stylus" scoped>
.undefin-wrapper
  text-align center
  font-size 16px
  color #ccc
  border 1px solid #ccc
  width 90%
  margin 0 auto 
  height 80px
  line-height 80px
  border-radius 10px
  & + .undefin-wrapper
    margin-top 38px
</style>
