<template>
  <div class="single_choice_box">
    <!-- 题目内容 -->
    <div class="question" v-html="question.question"></div>
    <!-- 答案选项 -->
    <div class="options">
      <div
        class="option"
        v-for="(opt, idx) in question.options"
        v-html="parse_opt(idx)"
        :key="idx"
        :class="get_option_class(idx)"
        @click="option_click(idx)"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SingleChoice",
  data() {
    return {
      selected: -1
    };
  },
  props: {
    question: Object,
    question_num: {
      type: Number,
      default: 0
    },
    is_view: {
      type: Boolean,
      default: false
    }
  },
  watch:{
    question: {
      handler: "init_data", //调用方法
      immediate: true //进入立即执行一次
    }
  },
  methods: {
    //初始化选项
    init_data(){
      this.selected=-1
    },
    //获取选项classname
    get_option_class(idx){
      let class_name=[]
      if(this.selected==idx){
        class_name.push("selected")
      }

      if(this.selected>-1){
        //判断对错
        if(idx==this.question.answer){
          class_name.push("right")
        }
        else if(this.selected==idx){
          class_name.push("wrong")
        }
      }

      return class_name;
    },
    //选项单击
    option_click(idx){
      if(this.selected>-1){return}
      this.selected=idx

      //检测答对答错情况
      let qa=false
      if(this.selected==this.question.answer){
        qa=true
      }
      this.$emit("change",{
        question_id:this.question.id,
        result:qa
      })
    },
    //解析选项值
    parse_opt(idx) {
      if (this.question.question_type == 1) {
        //选择题
        return String.fromCharCode(65 + idx) + "、" + this.question.options[idx];
      }
      if (this.question.question_type == 2) {
        //判断题
        return this.question.options[idx];
      }

      return this.question.options[idx];
    }
  },
  components: {}
};
</script>
<style lang="stylus" scoped>
.single_choice_box
    text-align center
    font-size 14px
    text-align left
    width 90%
    margin 0 auto
    margin-top 40px
    .question
        font-weight bold
    .options
        margin-top 30px
        .option
            border 1px solid #ccc
            border-radius 8px
            padding 10px 20px
            line-height 24px
            margin-bottom 20px
            &.selected
                border-color #000
                background #f9f9f9
            &.right
                border-color green
                background #e8ffe8
            &.wrong
                border-color red
                background #f1eceb
</style>
