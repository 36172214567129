<template>
  <component
    :is="currentComponent"
    :question_num="question_num"
    :question="question"
    :class="{ is_view: is_view }"
    :is_view="is_view"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>
<script>
import Undefind from "../undefind/undefind.vue"; //未定义题型
import SingleChoice from "../single_choice/single_choice.vue"; //单选+判断
export default {
  name: "QA",
  props: {
    question: {
      type: Object,
      default: function() {
        return {};
      }
    },
    question_num: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    is_view: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  computed: {
    currentComponent() {
      let component = null;
      //根据不同题型渲染不同子模板
      switch (this.question.question_type) {
        case "1":
          component = SingleChoice;
          break;
        case "2":
          component = SingleChoice;
          break;
        default:
          component = Undefind;
          break;
      }
      return component;
    }
  },
  methods:{
  }
};
</script>
<style lang="stylus" scoped>
.is_view {
  pointer-events: none;
}
</style>
